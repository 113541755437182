.table__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

thead {
  background: rgba(196, 196, 196, 0.14);
  border: none;
  /* width: 80vw; */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 20px;
}

th {
  flex: 1;
  justify-self: flex-start;
}

table tr:nth-child(even) {
  background: rgba(196, 196, 196, 0.14);
}

td {
  border: none;
  flex: 1;
}

tr {
  width: 87vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  grid-gap: 30px;
  padding: 0px 20px;
  text-align: center;
  padding-top: 20px;
  box-sizing: border-box;
}

.items {
  margin-bottom: 1rem;
}

.paginate ul {
  display: flex;
  grid-gap: 10px;
  width: 10%;
  position: relative;
  top: -30%;
  margin-top: 20px;
  left: 80%;
  align-items: flex-end;
}

.paginate li {
  cursor: pointer;
  list-style: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.34);
  font-size: small;
}
.paginate li:nth-of-type(1) {
  cursor: pointer;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;

  border: none !important;
}

.paginate li:hover {
  background: rgba(0, 0, 0, 0.12);
}

/* Dashboard */
.hr__dashboard__container {
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 100vh;
  position: relative;
  left: 13%;
  box-sizing: border-box;
  overflow: hidden;
  background: rgba(228, 227, 227, 0.216);
}

.hr__dashboard__header {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 70px;
  width: 100%;
  padding-left: 40px;
  box-sizing: border-box;
  position: relative;
  background: #fff;
}

.header__search {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex: 1;
}
.header__search input {
  outline: none;
  border: none;
  box-sizing: border-box;
  padding-left: 10px;
}

.header__divider {
  border-right: 2px solid rgba(158, 158, 158, 0.512);
  height: 50%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex: 1;
  position: absolute;
  left: 40%;
}
.header__details {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  box-sizing: border-box;
  justify-content: flex-end;
  padding-right: 10%;
}
.header__details img {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
}
.header__details img:hover {
  cursor: pointer;
}

.uploadDp {
  position: relative;
  background: none;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 55px;
  top: 5px;
  color: rgba(255, 255, 255, 0.008);
  border-radius: 50%;
}
.uploadDp:hover {
  background: rgba(0, 0, 0, 0.53);
  color: #000;
}

.hr__dashboard__text {
  width: 100%;
  height: 100px;
  padding-left: 40px;
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
}

.hr__dashboard__cards {
  display: grid;
  box-sizing: border-box;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  padding-left: 40px;
}

.dashboard__activities__card {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  border-bottom: 1px solid rgba(95, 94, 94, 0.1);
  box-sizing: border-box;
  padding: 10px;
  font-size: 12px;
}

.dashboard__activities {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 550px;
  height: 320px;
  padding: 10px 20px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  margin-left: 40px;
  margin-top: 20px;
  background: #fff;
}

/* Appraisal Report */
.container__content__select {
  display: "flex";
  justify-content: "center";
  align-items: "center";
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 20px;
  max-height: 200px;
}
.container__content__select:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: context-menu;
}

/* Employees Page */

.btn__action::after {
  content: "";
  display: flex;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 90%;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background: #fff;
  z-index: 99;
}

.btn {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  gap: "10px";
}
.btn button {
  width: 100px;
  height: 40px;
  /* background: rgb(76, 97, 237); */
  color: #fff;
  border-radius: 5px;
  display: "flex";
  align-items: "center";
  justify-content: "center";
}

.btn__start {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background: rgb(75, 225, 75);
  color: #fff;
  width: 100px;
  height: 40px;
  border-radius: 5px;
}
.btn__stop {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background: rgb(233, 47, 47);
  color: #fff;
  width: 100px;
  height: 40px;
  border-radius: 5px;
}
.btn__pending {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background: rgb(53, 47, 233);
  color: #fff;
  width: 100px;
  height: 40px;
  border-radius: 5px;
}

.checkbox {
  display: flex;
  align-items: center;
  min-width: auto;
  height: 100%;
  gap: 5px;
}

.formHeading {
  font-size: 16px;
  font-weight: 700;
}

/* Start Appraisal */

.StartAppraisal__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.StartAppraisal__container form {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  gap: 10px;
}
.appraisal__button {
  width: 200px;
  height: 50px;
  background: rgb(2, 4, 19);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.appraisal__input {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  width: 100%;
}
.appraisal__input input {
  width: 100%;
  height: 50px;
  padding-left: 30px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.quarter__container {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}
.quarter__container button {
  display: flex;
  gap: 10px;
}
