.most__header {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid whitesmoke;
  padding: 0.5rem 2rem;
  /* position: fixed; */
  background-size: cover;
  background-position: center;
  color: black;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.most__profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.most__title {
  display: flex;
  /* flex: 0.3; */
  justify-content: flex-start;
  text-align: left;
  align-items: center;
}

.most__name {
  display: flex;
  /* flex: 0.3; */
  justify-content: flex-end;
  text-align: right;
  align-items: center;
}

.most__children {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.dp {
  width: 50px;
  height: 50px;
  background: url("../../assets/user.png");
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 1rem;
}

.dp img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.profile__imagedp {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  height: 300px;
  width: 300px;
  background: url("../../assets/user.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
}

.profile__imagedp img {
  border: 1px solid rgba(184, 187, 184, 0.185);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  object-fit: cover;
}

.uploadDp {
  position: relative;
  background: none;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 55px;
  top: 5px;
  color: rgba(255, 255, 255, 0.008);
  border-radius: 50%;
}
.uploadImage {
  position: relative;
  background: none;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 55px;
  /* top: -5px; */
  color: rgba(255, 255, 255, 0.008);
  border-radius: 50%;
  bottom: -100px;
}

.uploadDp:hover {
  background: rgba(0, 0, 0, 0.53);
  color: #000;
}

@media screen and (max-width: 1050px) {
  .most__children {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 700px) {
  .most__children {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 550px) {
  .most__children {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
