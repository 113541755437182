.select__container {
  width: 100px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 30px;
  cursor: context-menu;
}

.select__textarea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.31);
  box-sizing: border-box;
  cursor: context-menu;
  padding-left: 20px;
  padding-right: 15px;
}

.select__display__children {
  width: 100%;
  max-height: 200px;
  display: flex;
  box-sizing: border-box;
  padding: 20px;
  flex-direction: column;
  position: absolute;
  z-index: 99;
  top: 40px;
}

@media (max-width: 480px) {
  .select__container {
    width: 380px;
  }
}
@media (min-width: 576px) {
  .select__container {
    width: 400px;
  }
}
