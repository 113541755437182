.modalContainer {
  width: 40vw;
  min-height: 20vw;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.modalContainer form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
}
.modalContainer form input {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 0 10px;
  box-sizing: border-box;
}
.modalContainer form select {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 0 10px;
  box-sizing: border-box;
}
.modalContainer form textarea {
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
}
.modalContainer form div {
  display: flex;
  flex-direction: column;
}
/* .modalContainer form .btn {
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 0 10px;
  box-sizing: border-box;
  background: rgba(15, 86, 179);
  margin-left: auto;
  cursor: pointer;
  color: #fff;
} */

.MuiSelect-select.MuiSelect-select {
  padding-right: 0px !important;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  box-sizing: border-box;
}
/*
.MuiInput-underline:after {
  display: none !important;
}
.MuiInput-underline:before {
  display: none !important;
} */

.tableIcon {
  background: none;
  border: none;
  padding: 0;
  margin: 10px;
  cursor: pointer;
}

/* .cancelBtn {
  position: absolute;
  right: 0;
  margin-right: 15px;
  cursor: pointer;
} */
