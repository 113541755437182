.loginBg {
    width: 100%;
    height: 100vh;
    display: flex;
    /* justify-content: center;
    align-items: center; */
}

.form {
    display: flex;
    flex: 1;
    background: white;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding-top: 15rem;
}

.form img {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.form h3 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}