.tooltipContainer {
    position: relative;
}

.tooltipBox {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    top: calc(100% + 5px);
    display: none;
    left: 50%;
}

.tooltipBox.visible {
    display: block;
}

.tooltipArrow {
    position: absolute;
    top: -10px;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
}