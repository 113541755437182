.addItemBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  width: 180px;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  background: rgb(2, 4, 84);
  cursor: pointer;
  margin: 10px 0px 10px auto;
}
.addItemBtnContainer span:nth-of-type(1) {
  width: 20px;
  height: 20px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
.addItemBtnContainer span:nth-of-type(2) {
  width: 100%;
  height: 100%;
  flex: 1.5;
  color: #fff;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
