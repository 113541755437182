.btn {
  width: 150px;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 0 10px;
  box-sizing: border-box;
  background: rgba(15, 86, 179);
  margin-left: auto;
  cursor: pointer;
  color: #fff;
}
